import React from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import './index.css';
import ConfiguratorOne from './components/web-component/ConfiguratorOne';
import WheelZoom from './components/web-component/WheelZoom';
import r2wc from "@r2wc/react-to-web-component";


const WcConfigurator = r2wc(ConfiguratorOne, {
  props: {
    onloading: 'string',
    color: 'string',
    colormap: 'string',
    coloroptions: 'string',
    paint: 'number',
    suspension: 'number',
    susval: 'number',
    maxsus: 'number',
    autorotate: 'bool',
    background: 'number',
    carid: 'string',
    wheelid: 'string',
    diameter: 'string',
    iscustom: 'bool',
    wheelurl: 'string',
    carurl: 'string',
    componentid: 'string',
    imageloader: 'string',
    loadercolor: 'string',
    hdrilightintensity: 'number',
    hdrfile: 'string',
  }
});

customElements.define("web-configurator", WcConfigurator);

class WcWheelZoom extends HTMLElement {
  constructor() {
    super();
    this.observer = new MutationObserver(() => this.update());
    this.observer.observe(this, { attributes: true });
  }

  connectedCallback() {
    this._innerHTML = this.innerHTML;
    this.mount();
  }

  disconnectedCallback() {
    this.unmount();
    this.observer.disconnect();
  }

  update() {
    // this.unmount();
    this.mount();
  }

  mount() {
    const props = {
      ...this.getProps(this.attributes),
      ...this.getEvents(),
      children: this.parseHtmlToReact(this.innerHTML)
    };
    render(<WheelZoom {...props} />, this);
  }

  unmount() {
    unmountComponentAtNode(this);
  }

  parseHtmlToReact(html) {
    return html;
  }

  getProps(attributes) {
    return [ ...attributes ]
      .filter(attr => attr.name !== 'style')
      .map(attr => this.convert(attr.name, attr.value))
      .reduce((props, prop) =>
        ({ ...props, [prop.name]: prop.value }), {});
  }

  // attribute prefix name with 'on'
  getEvents() {
    return Object.values(this.attributes)
      .filter(key => /on([a-z].*)/.exec(key.name))
      .reduce((events, ev) => ({
        ...events,
        [ev.name]: args =>
        this.dispatchEvent(new CustomEvent(ev.name, { ...args }))
      }), {});
  }

  convert(attrName, attrValue) {
    let value = attrValue;
    if (attrValue === 'true' || attrValue === 'false')
      value = attrValue === 'true';
    else if (!isNaN(attrValue) && attrValue !== '')
      value = +attrValue;
    else if (/^{.*}/.exec(attrValue))
      value = JSON.parse(attrValue);
    return {
      name: attrName,
      value: value
    };
  }

  static get observedAttributes() {
    return [
      "onloading",
      "wheelid",
    ];
  }
}

if (!customElements.get("web-configurator-wheelzoom")) {
  customElements.define("web-configurator-wheelzoom", WcWheelZoom);
}