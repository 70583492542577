import { applyProps, useFrame } from '@react-three/fiber';
import { useState, useRef, useEffect, useMemo } from 'react'
import { Center, Text3D, useCursor } from '@react-three/drei';


import * as THREE from "three";

function Wheel(props) {
  const {
    scene,
    side,
    dot,
    wheelScale,
    setWheelSize,
    tyreDepth,
    tyreWheelCircleNodes,
    brakeScene,
    magnifyScene,
    hideBrake,
    setShowDelayLoading,
    setEventLoading,
    isZoomingWheel,
    onClickWheel,
  } = props;

  const wheelRef = useRef()
  const zoomTextRef = useRef()
  const magnifyRef = useRef()
  const light = useRef();
  const [wheelFacePoint, setWheelFacePoint] = useState(0);
  const [wheelFaceAndEdgeDistance, setWheelFaceAndEdgeDistance] = useState(0);
  const [wheelSizeAfterGetFacePoint, setWheelSizeAfterGetFacePoint] = useState(0);
  const [dotTarget, setDotTarget] = useState(new THREE.Vector3(0));

  const [hovered, setHovered] = useState(false)

  useCursor(hovered, /*'pointer', 'auto', document.body*/);

  useFrame((state) => {
    if (!zoomTextRef.current || !magnifyRef.current) {
      return;
    }
    if (hovered) {
      zoomTextRef.current.position.set(0, Math.sin(state.clock.elapsedTime * 2) / 50, 0);

      if (side === 'right') {
        magnifyRef.current.position.set(-0.4, -0.05 + (Math.sin(state.clock.elapsedTime * 2) / 50), -0.1);
      } else {
        magnifyRef.current.position.set(0.4, -0.05 + (Math.sin(state.clock.elapsedTime * 2) / 50), 0.1);
      }
    } else {
      zoomTextRef.current.position.set(0,0,0);
      if (side === 'right') {
        magnifyRef.current.position.set(-0.4,-0.05, 0);
      } else {
        magnifyRef.current.position.set(0.4,-0.05, 0.1);
      }
    }
  });

  useEffect(() => {
    const target = new THREE.Vector3(0)
    dot.getWorldPosition(target)
    setDotTarget(target);
    if (setShowDelayLoading) {
      setShowDelayLoading(true)
    }
  }, [dot])

  const wheelEdge = useMemo(() => {
    const mesh = scene.children.find(item => item.type === 'Mesh' && item.name.includes('wheel_edge'));
    return mesh;
  }, [scene])

  function setWheelSizeToCar() {
    if (!setWheelSize) {
      return;
    }
    const bb = new THREE.Box3().setFromObject(scene);
    const wheelSizeTarget = new THREE.Vector3();
    bb.getSize(wheelSizeTarget)
    setWheelSize(wheelSizeTarget);
  }

  useEffect(() => {
    setWheelSizeToCar();
  }, [wheelScale])

  useEffect(() => {
    applyProps(wheelEdge, { visible: false })
    /**
     * Raycaster
     */
    const raycaster = new THREE.Raycaster()
    const rayOrigin = dotTarget;
    const rayZdir = side === 'right' ? -5 : 5;

    const rayDirection = new THREE.Vector3(0, 0, rayZdir)
    rayDirection.normalize()
    raycaster.set(rayOrigin, rayDirection)
    setTimeout(() => {
      const intersects = raycaster.intersectObjects([tyreWheelCircleNodes])
      const foundedWheelSizeFace = intersects.find((item) => item.object.type === 'Mesh' && item.object.name.includes('wheelsize_circle'));
      if (foundedWheelSizeFace) {
        setWheelFacePoint(foundedWheelSizeFace.distance);
      }
    }, 500)

    if (wheelFacePoint === 0) {
      return;
    }
    const bb = new THREE.Box3().setFromObject(scene);
    const wheelSizeTarget = new THREE.Vector3();
    bb.getSize(wheelSizeTarget)
    setWheelSizeAfterGetFacePoint(wheelSizeTarget.z)
    setTimeout(() => {
      const wheelEdgeTarget = new THREE.Vector3()
      wheelEdge.getWorldPosition(wheelEdgeTarget)
      const raycaster = new THREE.Raycaster()
      const rayOrigin = dotTarget;
      const rayZdir = side === 'right' ? -5 : 5;
      const rayDirection = new THREE.Vector3(0, 0, rayZdir)
      rayDirection.normalize()
      raycaster.set(rayOrigin, rayDirection)
      const intersects = raycaster.intersectObjects([wheelEdge])
      const foundedWheelEdge = intersects.find((item) => item.object.type === 'Mesh' && item.object.name.includes('wheel_edge'));
      if (foundedWheelEdge) {
        setWheelFaceAndEdgeDistance(foundedWheelEdge.distance - wheelFacePoint);
      }
      if (setShowDelayLoading) {
        setShowDelayLoading(false)
      }
      if (setEventLoading) {
        setEventLoading()
      }
    }, 500)
  }, [scene, dotTarget, side, wheelEdge, tyreWheelCircleNodes, wheelFacePoint])

  function handleWheelClick(e) {
    e.stopPropagation();
    onClickWheel('side', wheelRef.current)
  }

  function handleHover(e) {
    e.stopPropagation();
    if (isZoomingWheel) {
      return undefined;
    }
    setHovered(true);
  }

  // useHelper(light, THREE.PointLightHelper, 1, "blue");
  if (side === 'right')
    return (
      <group
        scale={[1, 1, 1 - tyreDepth]}
        position={[dotTarget.x, dotTarget.y + 0.4, dotTarget.z - wheelFacePoint - wheelFaceAndEdgeDistance + (wheelSizeAfterGetFacePoint / 2)]}
        onClick={(e) => handleWheelClick(e)}
        onPointerOver={handleHover}
        onPointerOut={(e) => setHovered(false)}
      >
        { hideBrake ? <></> : <primitive object={brakeScene} rotation={[Math.PI, 0, 0]} scale={[wheelScale * 1.1, wheelScale * 1.1, 1]} /> }
        <primitive
          ref={wheelRef}
          object={scene}
          rotation={[0, Math.PI, 0]}
          scale={[wheelScale, wheelScale, 1]}
        />
        <pointLight ref={light} position={[0, 0.2, -0.4]} intensity={1} distance={2} decay={1} />
        {
          isZoomingWheel
            ? <></>
            : <Center position={[0.4, 0.08, -0.2]}>
              <Text3D
                ref={zoomTextRef}
                scale={[1, 1, 0.1]}
                rotation={[0, Math.PI, 0]}
                curveSegments={32}
                bevelEnabled
                bevelSize={0.01}
                bevelThickness={0.1}
                height={0.5}
                lineHeight={0.5}
                letterSpacing={0.01}
                size={.06}
                visible={hovered}
                font="/Inter_Bold.json">
                {`ZOOM`}
                <meshStandardMaterial color="#ff8512" roughness={1} metalness={0.5} />
              </Text3D>
              <group key="magnifyRight" ref={magnifyRef} visible={hovered}>
                <primitive
                  object={magnifyScene}
                  rotation={[-0.9,Math.PI,0]}
                />
              </group>
            </Center>
        }
      </group>
    );
  return (
    <group
      scale={[1, 1, 1 - tyreDepth]}
      position={[dotTarget.x, dotTarget.y + 0.4, dotTarget.z + wheelFacePoint + wheelFaceAndEdgeDistance - (wheelSizeAfterGetFacePoint / 2)]}
      onClick={(e) => handleWheelClick(e)}
      onPointerOver={handleHover}
      onPointerOut={(e) => setHovered(false)}
    >
      { hideBrake ? <></> : <primitive object={brakeScene} scale={[wheelScale * 1.1, wheelScale * 1.1, 1]} /> }
      <primitive
        ref={wheelRef}
        object={scene}
        scale={[wheelScale, wheelScale, 1]}
      />
      <pointLight ref={light} position={[0, 0.2, 0.4]} intensity={1} distance={2} decay={1} />
      {
        isZoomingWheel
          ? <></>
          : <Center position={[0.4, 0.08, 0.2]}>
            <Text3D
              scale={[1, 1, 0.1]}
              ref={zoomTextRef}
              curveSegments={32}
              bevelEnabled
              bevelSize={0.01}
              bevelThickness={0.1}
              height={0.5}
              lineHeight={0.5}
              letterSpacing={0.01}
              size={.06}
              visible={hovered}
              font="/Inter_Bold.json">
              {`ZOOM`}
              <meshStandardMaterial color="#ff8512" roughness={1} metalness={0.5} />
            </Text3D>
            <group key="magnifyLeft" ref={magnifyRef} visible={hovered}>
              <primitive
                object={magnifyScene}
                rotation={[0.9,0,0]}
              />
            </group>
          </Center>
      }
    </group>
  );
}

export default Wheel;
