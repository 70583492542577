import Wheel from './Wheel';
import { useEffect, useMemo, useState } from 'react'
import { applyProps } from '@react-three/fiber';
import { useGLTF } from '@react-three/drei';
import brakeModel from '../assets/brake/brakes_basesize.glb';
import magnifyGlass from '../assets/magnify.glb';

function WheelAndDots(props) {
  const {
    wheelFile,
    dots,
    tyreDepth,
    tyreNodes,
    wheelScale,
    selectedCarKey,
    selectedWheelKey,
    setWheelSize,
    hasDrumBrake,
    setEventLoading,
    setShowDelayLoading,
    isZoomingWheel,
    onClickWheel,
  } = props;

  const { scene: wheelScene } = useGLTF(wheelFile, '/draco/gltf/')
  const { scene: brakeScene } = useGLTF(brakeModel, '/draco/gltf/')
  const { scene: magnifyScene } = useGLTF(magnifyGlass, '/draco/gltf/')

  const wheelFrontLeftScene = useMemo(() => wheelScene.clone(), [wheelScene])
  const wheelFrontRightScene = useMemo(() => wheelScene.clone(), [wheelScene])
  const wheelRearLeftScene = useMemo(() => wheelScene.clone(), [wheelScene])
  const wheelRearRightScene = useMemo(() => wheelScene.clone(), [wheelScene])

  const brakeFrontLeftScene = useMemo(() => brakeScene.clone(), [brakeScene])
  const brakeFrontRightScene = useMemo(() => brakeScene.clone(), [brakeScene])
  const brakeRearLeftScene = useMemo(() => brakeScene.clone(), [brakeScene])
  const brakeRearRightScene = useMemo(() => brakeScene.clone(), [brakeScene])

  const magnifyFrontLeftScene = useMemo(() => magnifyScene.clone(), [magnifyScene])
  const magnifyFrontRightScene = useMemo(() => magnifyScene.clone(), [magnifyScene])
  const magnifyRearLeftScene = useMemo(() => magnifyScene.clone(), [magnifyScene])
  const magnifyRearRightScene = useMemo(() => magnifyScene.clone(), [magnifyScene])

  useEffect(() => {
    wheelFrontLeftScene.traverse(function(obj) {
      if (obj.children && obj.children.length && obj.children[0].type === "PointLight") {
        applyProps(obj, { visible: false })
      }
    })
  }, [wheelFrontLeftScene])

  useEffect(() => {
    wheelFrontRightScene.traverse(function(obj) {
      if (obj.children && obj.children.length && obj.children[0].type === "PointLight") {
        applyProps(obj, { visible: false })
      }
    })
  }, [wheelFrontRightScene])

  useEffect(() => {
    wheelRearLeftScene.traverse(function(obj) {
      if (obj.children && obj.children.length && obj.children[0].type === "PointLight") {
        applyProps(obj, { visible: false })
      }
    })
  }, [wheelRearLeftScene])

  useEffect(() => {
    wheelRearRightScene.traverse(function(obj) {
      if (obj.children && obj.children.length && obj.children[0].type === "PointLight") {
        applyProps(obj, { visible: false })
      }
    })
  }, [wheelRearRightScene])

  function setWheelSizeFrontLeft(event) {
    setWheelSize(event)
  }

  if (dots.length === 0) {
    return <></>
  }

  return (
    <group>
      <Wheel
        key={selectedWheelKey + 'frontLeft' + selectedCarKey}
        scene={wheelFrontLeftScene}
        brakeScene={brakeFrontLeftScene}
        magnifyScene={magnifyFrontLeftScene}
        side="left"
        dot={dots[0]}
        tyreNodes={tyreNodes[0]}
        tyreWheelCircleNodes={tyreNodes[4]}
        tyreDepth={tyreDepth}
        wheelScale={wheelScale}
        setWheelSize={setWheelSizeFrontLeft}
        setEventLoading={setEventLoading}
        setShowDelayLoading={setShowDelayLoading}
        isZoomingWheel={isZoomingWheel}
        onClickWheel={onClickWheel}
      />
      <Wheel
        key={selectedWheelKey + 'frontRight' + selectedCarKey}
        scene={wheelFrontRightScene}
        brakeScene={brakeFrontRightScene}
        magnifyScene={magnifyFrontRightScene}
        side="right"
        dot={dots[1]}
        tyreNodes={tyreNodes[1]}
        tyreWheelCircleNodes={tyreNodes[5]}
        tyreDepth={tyreDepth}
        wheelScale={wheelScale}
        isZoomingWheel={isZoomingWheel}
        onClickWheel={onClickWheel}
      />
      <Wheel
        key={selectedWheelKey + 'rearLeft' + selectedCarKey}
        scene={wheelRearLeftScene}
        brakeScene={brakeRearLeftScene}
        magnifyScene={magnifyRearLeftScene}
        side="left"
        dot={dots[2]}
        tyreNodes={tyreNodes[2]}
        tyreWheelCircleNodes={tyreNodes[6]}
        tyreDepth={tyreDepth}
        wheelScale={wheelScale}
        hideBrake={hasDrumBrake}
        isZoomingWheel={isZoomingWheel}
        onClickWheel={onClickWheel}
      />
      <Wheel
        key={selectedWheelKey + 'rearRight' + selectedCarKey}
        scene={wheelRearRightScene}
        brakeScene={brakeRearRightScene}
        magnifyScene={magnifyRearRightScene}
        side="right"
        dot={dots[3]}
        tyreNodes={tyreNodes[3]}
        tyreWheelCircleNodes={tyreNodes[7]}
        tyreDepth={tyreDepth}
        wheelScale={wheelScale}
        hideBrake={hasDrumBrake}
        isZoomingWheel={isZoomingWheel}
        onClickWheel={onClickWheel}
      />
    </group>
  )
}

export default WheelAndDots;
